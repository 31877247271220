<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center w-100 mb-1">
      <h3>الإشعارات</h3>
      <b-button variant="primary" @click="onClickedAdd" v-can="{ slug: $route.meta.slug, action: 'C' }">
        <feather-icon icon="userIcon" /> إرسال إشعار
      </b-button>
    </div>

    <data-table ref="estatesTable" :fields="fields" ep="/notifications"> </data-table>

    <form-modal
      ref="estateModalNotifictions"
      :formSchema="estatesFormSchema"
      title="إرسال إشعار"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
    </form-modal>
  </b-card>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
import DataTable from "@/components/data-table/index";
import { mapActions, mapGetters } from "vuex";
import FormModal from "@/components/FormModal.vue";

export default {
  components: {
    BCard,
    DataTable,
    BButton,
    FormModal
  },

  data: () => {
    return {
      imageSlide: [],
      staticImages: [],
      fields: [
        { key: "title", label: "عنوان الإشعار" },
        { key: "body", label: "التفاصيل" },
      ],
      activModel: false,
      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            label: "عنوان الإشعار",
            rules: "required",
          },
        },
        body: {
          component: "FormTextarea",
          key: "body",
          attrs: {
            label: "التفاصيل",
            rules: "required",
          },
        },
      },
      utils: {
        status: "",
      },
      filter: "",
    };
  },
  methods: {
    ...mapActions("manageApp/notifictions", ["sendNotifictions"]),

    onClickedAdd() {
      this.$refs.estateModalNotifictions.init({});
      this.activModel = true;
    },

    onModalConfirmed(form) {
        this.sendNotifictions({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      
    },
  },

  computed: {
    ...mapGetters("manageApp/notifictions", ["loading"]),
  },
};
</script>
    <style lang="scss" >
.top-m {
  padding-top: 7px;
}
</style>